import React, { useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { RootState } from 'handlers';
import { uploadStudentAidData, getStudentLoanAssistanceData } from 'thunks';
import { FlowComponentType } from 'routes/types';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import DropzoneWidget, { useFiles } from 'components/DropzoneWidget';
import { getApplicationData } from 'selectors/getApplicationData';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import CardSequence from 'components/Common/CardSequence';
import { UploadLoanInformationResult } from 'enums/StudentLoanForgivenessFlowResults';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import { ButtonType } from 'components/Button/Button';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';

import styles from './UploadLoanInformation.module.scss';

const FilesPreview = React.lazy(() => import('components/FilesPreview'));

const renameFiles = (cb: (files: File[]) => void, filePrefix?: string) => (files: File[]) => {
  const prefix = filePrefix ? `${filePrefix} ` : '';
  return cb(files.map((file) => new File([file], `${prefix}${file.name}`, { type: file.type })));
};

const UploadLoanInformation = ({ handleNext, navigationInfo, flags }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { handleAddFiles, files, handleRemoveFiles } = useFiles();
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);

  const {
    applicationId,
    applicationData: { applyProgress },
  } = useSelector(getStudentLoanApplication);

  const { isApplicationStatusUpdating } = useSelector(getApplicationData);
  const { isLoading } = useSelector((state: RootState) => state.filesUpload);

  const handleContinue = async () => {
    const filesForUpload: File[] = files.map((item) => item.file);

    analytics.track('Upload Loan Information in Progress');
    const { estimateChanged } = await dispatchWithUnwrap(
      uploadStudentAidData({
        applicationId: applicationId!,
        file: filesForUpload[0],
      }),
    );

    if (estimateChanged) {
      const { currentPath: currentPathData, recommendedPath: recommendedPathData } = await dispatchWithUnwrap(
        getStudentLoanAssistanceData({ applicationId: applicationId! }),
      );
      const savings =
        currentPathData?.totalPayment !== undefined && recommendedPathData?.totalPayment !== undefined
          ? currentPathData?.totalPayment - recommendedPathData?.totalPayment
          : null;

      handleNext(savings ? UploadLoanInformationResult.EstimateChanged : UploadLoanInformationResult.NoSavings);
    } else {
      handleNext(UploadLoanInformationResult.NoChange);
    }
  };

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <FormContainer
          title="Let's Determine Your Programs"
          subtitle="We need more detailed information about your loans to determine your best course of action."
          className={styles.formContainer}
        >
          <p>Please follow these steps:</p>

          <CardSequence
            steps={[
              {
                title: (
                  <>
                    1. Log in to{' '}
                    <a href="https://studentaid.gov/fsa-id/sign-in" target="_blank" rel="noreferrer">
                      studentaid.gov
                    </a>
                    .
                  </>
                ),
              },
              {
                title: '2. View Details',
                body: (
                  <>
                    From the dashboard, click the <b>View Details</b> button. The screen will refresh.
                    <img src="/student-aid-images/image1.png" alt="Example" width="100%" />
                  </>
                ),
              },
              {
                title: '3. Download My Aid Data',
                body: (
                  <>
                    From the Details Page click the <b>Download My Aid Data</b> button.
                    <img src="/student-aid-images/image2.png" alt="Example" width="100%" />
                  </>
                ),
              },
            ]}
          />

          <p>Upload the file below:</p>

          <DropzoneWidget
            handleFiles={renameFiles(handleAddFiles, 'StudentAidData')}
            extensions={['text/plain']}
            multiple={false}
          />

          {files.length ? (
            <Suspense fallback={<ReactLoading type="spin" color="#fff" />}>
              <FilesPreview files={files} handleRemove={handleRemoveFiles} />
            </Suspense>
          ) : (
            <></>
          )}

          {applyProgress?.loanInformation && (
            <>
              <div className={styles.uploaded}>
                <CheckIcon className={styles.checkIcon} />
                <p className={styles.verifiedLabel}>Loan information uploaded</p>
              </div>

              {!files.length && <Button onClick={() => handleNext(UploadLoanInformationResult.NoChange)}>Next</Button>}
            </>
          )}

          {Boolean(!applyProgress?.loanInformation || files.length) && (
            <div className={styles.buttonContainer}>
              <Button
                disabled={!files.length}
                className={styles.button}
                onClick={handleContinue}
                isLoading={isLoading || isApplicationStatusUpdating}
              >
                Save and Continue
              </Button>
              <Button type={ButtonType.Transparent} onClick={() => setAreYouSureModalOpen(true)}>
                Not Now
              </Button>
            </div>
          )}

          {areYouSureModalOpen && (
            <AreYouSureModal
              onClose={() => setAreYouSureModalOpen(false)}
              onNext={() => setAreYouSureModalOpen(false)}
              onNotNow={() => handleNext(UploadLoanInformationResult.NotNow)}
            />
          )}
        </FormContainer>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default UploadLoanInformation;
